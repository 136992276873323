import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { ToastContainer, toast } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "toast"
    }}>{`Toast`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`Place the `}<inlineCode parentName="p">{`<ToastContainer/>`}</inlineCode>{` in the app so it is always available. For example in the same stack as the top most `}<inlineCode parentName="p">{`<Router/>`}</inlineCode>{`.`}</p>
    <p>{`Make sure there is `}<strong parentName="p">{`only one ToastContainer in the app`}</strong>{`!`}</p>
    <p>{`Wherever you need to show a toast message, use the `}<inlineCode parentName="p">{`toast`}</inlineCode>{` to trigger message.`}</p>
    <p>{`The following toast parameters are supported`}</p>
    <ul>
      <li parentName="ul">{`state: any of the predifined types "active", "success", "warn", "error",`}</li>
      <li parentName="ul">{`title`}</li>
      <li parentName="ul">{`description`}</li>
      <li parentName="ul">{`actionLabel: Show action button with label given`}</li>
      <li parentName="ul">{`actionHandler: Define button callback function`}</li>
      <li parentName="ul">{`position: override default position with value "right", "center" or "left"`}</li>
      <li parentName="ul">{`hideClose: Don't show close (x) button`}</li>
    </ul>
    <Playground __position={1} __code={'<button onClick={() => toast.success({ message: \'Hello Hello!\' })}>\n  Show Success Toast\n</button>\n<button\n  onClick={() => toast.error({ message: \'Error Error!\', delay: 10000 })}\n>\n  Show Error Toast (10s delay)\n</button>\n<button\n  onClick={() =>\n    toast.message({\n      title: \'Launching App\',\n      description: \'If app did not launch click here!\',\n      actionLabel: \'Open\',\n      actionHandler: () => {\n        alert(\'App launch method might open a new tap\')\n      },\n      delay: 10000,\n    })\n  }\n>\n  Show Action Button Toast\n</button>\n<button onClick={() => toast.hide()}>Hides toasts</button>\n<ToastContainer position=\"center\" delay={5000} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ToastContainer,
      toast,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <button onClick={() => toast.success({
        message: "Hello Hello!"
      })}>Show Success Toast</button>
    <button onClick={() => toast.error({
        message: "Error Error!",
        delay: 10000
      })}>Show Error Toast (10s delay)</button>
    <button onClick={() => toast.message({
        title: "Launching App",
        description: "If app did not launch click here!",
        actionLabel: "Open",
        actionHandler: () => {
          alert("App launch method might open a new tap");
        },
        delay: 10000
      })}>Show Action Button Toast</button>
    <button onClick={() => toast.hide()}>Hides toasts</button>
    <ToastContainer position="center" delay={5000} mdxType="ToastContainer" />
    </Playground>
    <h2 {...{
      "id": "toastcontainer"
    }}>{`ToastContainer`}</h2>
    <Props of={ToastContainer} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      